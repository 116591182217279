<template>
  <div>
    <div class="row flex" style="height:100vh">
      <!-- Left side: Login form -->
      <div class="col-md-6 mt-20 p-5">
        <h3 class="text-xl text-center text-oonpayprimary mt-8 mb-8">
          Forgotten Password ?
        </h3>
        <div class="px-4 mb-4 ">
          <label for="Verification code (OTP)">Verification code (OTP)</label>
          <input type="text" v-model="passcode" placeholder="Enter verification code sent"
            class="border border-grey rounded w-full p-3" />
        </div>

        <div class="px-4 mb-4">
          <label for="new password">New Password</label>
          <input type="password" v-model="password" placeholder="Enter new password"
            class="border border-grey rounded w-full p-3" />
        </div>

        <div class="px-4 mb-4">
          <label for="confirm password">Confirm Password</label>
          <input type="password" v-model="confirm_password" placeholder="Confirm new password"
            class="border border-grey rounded w-full p-3" />
        </div>

        <div class="px-4 mb-10">
          <button @click="ResetPassword" class="
                    border border-teal-dark
                    bg-oonpayprimary
                    rounded
                    w-full
                    px-4
                    py-3
                    text-white
                    font-semibold
                  ">
            <b-spinner v-if="loading" small></b-spinner>

            Reset Password
          </button>
        </div>



      </div>

      <!-- Right side: Image -->
      <div class="d-none d-lg-flex  col-md-6 rightside">

      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      passcode: "",
      password: "",
      confirm_password: "",
      loading: false
    };
  },


  methods: {
    ResetPassword() {
      this.loading = true;

      const data = {
        pass_passcode: this.passcode,
        pass_password: this.password,
      };

      if (this.password.length < 6) {
        return this.$toasted.error(
          "password must be at least 6 charecters", { duration: 5000 }
        );
      }

      if (this.password != this.confirm_password) {
        return this.$toasted.error("Password do not match", { duration: 5000 });
      }
      // console.log(data)
      return this.$store
        .dispatch("dashboard/ResetPassword", data)
        .then((response) => {
          this.loading = false

          if (response.status == 200) {
            this.$toasted.success("password reset successfully", { duration: 5000 })
            this.$router.push("/account/merchant/login")
          }
          else
            return this.$toasted.error("Oooop! an error has occured", { duration: 5000 })
        })
        .catch(err => {
          this.loading = false;
          this.$toasted.error(err.response.data, { duration: 5000 });
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.rightside {
  background-image: url('../../../assets/auth/bg20.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
</style>
